body {
  background-color: #161f25;
  font-family: 'Open Sans', sans-serif;
}

.title {
  background-color: #100e17;
  font-family: 'Open Sans', sans-serif;
  font-size: large;
  justify-content: center;
  display: flex;
  padding-bottom: 20px;
}

.App {
  text-align: center;
  background-color: #202020;
  color: white;
}

.header {
  display: flex;
  background-color: #253949;
  box-shadow: 2px 2px 1px black;
}

.header-logo {
  height: 60px;
  margin-top: 4px;
  margin-left: 4px;
}

.nav {
  color: white;
  margin-left: auto;
  margin-right: 0;
}

.tab {
  background-color: #253949;
  color: white;
  font-size: large;
  padding: 1rem;
  border-radius: 2rem;
  border: none;
}

.tab:hover {
  background-color :azure;
  border-color: azure;
  color: rgb(0, 187, 201);
}

#chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 45%;
  height: 50%;
  margin: 0;
  padding: 0;
  margin-top: 80px;
  margin-left: 5px;
}

#donut {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 40%;
  margin: 0;
  padding: 0;
  margin-top: 80px;
}

#pie {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 40%;
  margin: 0;
  padding: 0;
  margin-top: 80px;
  margin-right: 25%;
}

#line {
  position: relative;
  bottom: 0;
  width: 50%;
  height: 40%;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  margin-top: 25%;
}

.box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1px;
  text-align: center;
}

.tile {
  width: 40vh;
  height: 35vh;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 100%;
  margin-left: 6vh;
  border-radius: 3vh;
  background-color: #253949;
  border: none;
  color: grey;
  padding-top: 23%;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.719);
}

.createProblem {
  background-color: rgb(45, 40, 65);
  color: white;
  height: 30px;
  font-size: large;
  margin-left: 8px;
  border-radius: 8px;
}

.createProblem:hover {
  background-color: rgb(0, 255, 136);
  color: black;
}

.inputContainer {
  background-color: #100e17;
}

.textbox {
  background-color: rgb(255, 255, 255);
  height: 30px;
  font-size: large;
  width: 13%;
  border-radius: 8px;
}

::placeholder {
  color: rgb(175, 175, 175);
}

.problemsDisplay {
  position: absolute;
  width: 100%;
  top: 140px;
}

.card {
  width: 100%;
  background: rgb(40, 49, 65);
  position: relative;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card:not(:first-child) {
  margin-left: -50px;
}

h1 {
  color: white;
}

p {
  margin: 1%;
  padding: 0;
}

.dash-container {
  padding-top: 30px;
}

.dash {
  display: grid;
  gap: 60px;
  grid-template-columns: 25% 25% 25%;
  justify-content: center;
}

.dashboard-tile {
  background-color: rgb(0, 132, 255);
  color: white;
  width: 25%vw;
  border-radius: 20px;
  text-align: center;
}

.dashboard-tile:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.dashboard-card:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.submissions-tile {
  background-color: rgb(255, 0, 0);
  color: white;
  width: 25%vw;
  border-radius: 20px;
  text-align: center;
}

.submissions-tile:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.submissions-card:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.problem-tile {
  background-color: rgb(255, 145, 0);
  color: white;
  width: 25%vw;
  border-radius: 20px;
  text-align: center;
}

.problem-tile:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.problem-card:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.profile-tile {
  background-color: magenta;
  color: white;
  width: 25%vw;
  border-radius: 20px;
  text-align: center;
}

.profile-tile:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.profile-card:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.leetcode-tile {
  background-color: rgb(0, 255, 136);
  color: white;
  width: 25%vw;
  border-radius: 20px;
  text-align: center;
}

.leetcode-tile:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.leetcode-card:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.settings-tile {
  background-color: rgb(68, 68, 68);
  color: white;
  width: 25%vw;
  border-radius: 20px;
  text-align: center;
}

.settings-tile:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.settings-card:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.img {
  padding-top: 20px;
  height: 100px;
}

.progress {
  color: white;
}

.text {
  color: white;
}

h2, p {
  color: azure;
}

.about {
  text-align: center;
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 2rem;
}

.link {
  height: 3rem;
  padding: 0.5rem;
}